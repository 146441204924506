<template>
  <base-section id="capi-features">
    <base-section-heading :title="$t('landing-capi.title')">
      {{ $t('landing-capi.about') }}
    </base-section-heading>

    <v-container>
      <v-row>
        <v-col
          v-for="(feature, i) in features"
          :key="i"
          cols="12"
          md="6"
        >
          <base-avatar-card
            v-bind="feature"
            align="left"
            horizontal
            :title="$t(feature.title)"
            :text="$t(feature.text)"
          />
        </v-col>
      </v-row>
    </v-container>
  </base-section>
</template>

<script>
  export default {
    name: 'SectionCapiFeatures',

    data: () => ({
      features: [
        {
          icon: 'mdi-map-marker-radius',
          title: 'landing-capi.title1',
          text: 'landing-capi.text1',
        },
        {
          icon: 'mdi-database-check',
          title: 'landing-capi.title2',
          text: 'landing-capi.text2',
        },
        {
          // color: 'primary',
          // dark: true,
          icon: 'mdi-eye',
          title: 'landing-capi.title3',
          text: 'landing-capi.text3',
        },
        {
          icon: 'mdi-monitor-dashboard',
          title: 'landing-capi.title4',
          text: 'landing-capi.text4',
        },
        {
          icon: 'mdi-finance',
          title: 'landing-capi.title5',
          text: 'landing-capi.text5',
        },
        {
          icon: 'mdi-help-circle-outline',
          title: 'landing-capi.title6',
          text: 'landing-capi.text6',
        },
      ],
    }),
  }
</script>
